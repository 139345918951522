import './App.css';
import frase1 from './frases/frase-01.png';
import frase2 from './frases/frase-02.png';
import frase3 from './frases/frase-03.png';
import frase4 from './frases/frase-04.png';
import frase5 from './frases/frase-05.png';
import frase6 from './frases/frase-06.png';
import frase7 from './frases/frase-07.png';

function App() {
  let frase;
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = dd + '/' + mm + '/' + yyyy;

  const dayNames = ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"];
  const dayNumber = Number(new Date().getDay());
  console.log(`Hoje é ${dayNames[dayNumber]}`);

  switch (dayNames) {
    case dayNames[dayNumber] === 'domingo':
      frase = frase1;
    break;
    case dayNames[dayNumber] === 'segunda':
      frase = frase2;
    break;
    case dayNames[dayNumber] === 'terça':
      frase = frase3;
    break;
    case dayNames[dayNumber] === 'quarta':
      frase = frase4;
    break;
    case dayNames[dayNumber] === 'quinta':
      frase = frase5;
    break;
    case dayNames[dayNumber] === 'sexta':
      frase = frase6;
    break;
    case dayNames[dayNumber] === 'sábado':
      frase = frase7;
    break;
  
    default:
      frase = frase1;
      break;
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={frase} className="App-frase" alt="logo" />
      </header>
    </div>
  );
}

export default App;
